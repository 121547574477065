import React from 'react';
import { convertToEmbedUrl } from '../utils/videoUtils';

function VideoPlayer({ url, isPrimary, onClick }) {
  const embedUrl = convertToEmbedUrl(url);

  const handleClick = (e) => {
    e.preventDefault();
    if (!isPrimary && onClick) {
      onClick(url);
    }
  };

  return (
    <div 
      className={`video-player ${isPrimary ? 'primary' : 'secondary'}`} 
      onClick={handleClick}
    >
      <iframe
        src={embedUrl}
        title="Embedded video"
        width={isPrimary ? "576" : "288"}
        height={isPrimary ? "324" : "162"}
        allow="fullscreen"
        allowFullScreen
        frameBorder="0"
      ></iframe>
    </div>
  );
}

export default VideoPlayer;