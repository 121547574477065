import React, { useState } from 'react';
import VideoPlayer from './VideoPlayer';

function ObservationCard({ observation }) {
  const [primaryVideo, setPrimaryVideo] = useState(observation.urls[0]);

  const cardStyle = {
    backgroundColor: getBackgroundColor(observation.sentiment),
  };

  function handleVideoClick(url) {
    setPrimaryVideo(url);
  }

  return (
    <div className="observation-card" style={cardStyle}>
      <h3>{observation.title}</h3>
      <p>{observation.description}</p>
      <div className="video-container">
        <VideoPlayer 
          url={primaryVideo} 
          isPrimary={true}
        />
        <div className="video-list">
          {observation.urls.filter(url => url !== primaryVideo).map((url, index) => (
            <VideoPlayer
              key={index}
              url={url}
              isPrimary={false}
              onClick={() => handleVideoClick(url)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function getBackgroundColor(sentiment) {
  switch (sentiment) {
    case 'Positive':
      return '#e6ffe6';
    case 'Negative':
      return '#ffe6e6';
    case 'Missed Opportunity':
      return '#ffffe6';
    default:
      return '#ffffff';
  }
}

export default ObservationCard;